import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';


import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class TestsService {

  private _url: string = this.commonService._hostName + 'admin-api-v1/tests.php?action=';
  private data_transfer_url:string = this.commonService._hostName + 'admin-api-v1/data_transfer.php?action=';
  private user_id;

  constructor(public http: HttpClient, public commonService: CommonService) {
    var currentUser = localStorage.getItem('currentUser');
    var retrievedObject = JSON.parse(currentUser);
    this.user_id = retrievedObject.id;
  }

  getSomeTests(start, aItemsPerPage, sort, type, searchTitle, searchQuestion, searchCountry, searchStage, searchGrade, searchSemester, searchTestSemester, searchSubject, searchUnit, searchLesson, searchTeacherId, teacherName, searchDateFrom, searchDateTo, searchSource, searchStatus, searchTestType, searchAddedBy, searchJob, searchPaid) {
    return this.http.get<any>(this._url + 'getSomeTests&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchTitle=' + searchTitle + '&searchQuestion=' + searchQuestion + '&searchCountry=' + searchCountry + '&searchStage=' + searchStage + '&searchGrade=' + searchGrade + '&searchSemester=' + searchSemester + '&searchTestSemester=' + searchTestSemester + '&searchSubject=' + searchSubject + '&searchUnit=' + searchUnit + '&searchLesson=' + searchLesson + '&searchTeacherId=' + searchTeacherId + '&teacherName=' + teacherName + '&searchDateFrom=' + searchDateFrom + '&searchDateTo=' + searchDateTo + '&searchSource=' + searchSource + '&searchStatus=' + searchStatus + '&searchTestType=' + searchTestType + '&searchAddedBy=' + searchAddedBy+ '&searchJob=' + searchJob + '&searchPaid=' + searchPaid + '&user_id=' + this.user_id);
  }

  getSearchTestsCount(sort, type, searchTitle, searchQuestion, searchCountry, searchStage, searchGrade, searchSemester, searchTestSemester, searchSubject, searchUnit, searchLesson, searchTeacherId, teacherName, searchDateFrom, searchDateTo, searchSource, searchStatus, searchTestType, searchAddedBy,searchJob, searchPaid) {
    return this.http.get<any>(this._url + 'getSearchTestsCount&sort=' + sort + '&type=' + type + '&searchTitle=' + searchTitle + '&searchQuestion=' + searchQuestion + '&searchCountry=' + searchCountry + '&searchStage=' + searchStage + '&searchGrade=' + searchGrade + '&searchSemester=' + searchSemester + '&searchTestSemester=' + searchTestSemester + '&searchSubject=' + searchSubject + '&searchUnit=' + searchUnit + '&searchLesson=' + searchLesson + '&searchTeacherId=' + searchTeacherId + '&teacherName=' + teacherName + '&searchDateFrom=' + searchDateFrom + '&searchDateTo=' + searchDateTo + '&searchSource=' + searchSource + '&searchStatus=' + searchStatus + '&searchTestType=' + searchTestType + '&searchAddedBy=' + searchAddedBy+ '&searchJob=' + searchJob + '&searchPaid=' + searchPaid);
  }

  getTestsCount(searchTitle, searchQuestion, searchCountry, searchStage, searchGrade, searchSemester, searchTestSemester, searchSubject, searchUnit, searchLesson, searchTeacherId, teacherName, searchDateFrom, searchDateTo, searchSource, searchStatus, searchTestType, searchAddedBy,searchJob, searchPaid) {
    return this.http.get<any>(this._url + 'getTestsCount&searchTitle=' + searchTitle + '&searchQuestion=' + searchQuestion + '&searchCountry=' + searchCountry + '&searchStage=' + searchStage + '&searchGrade=' + searchGrade + '&searchSemester=' + searchSemester + '&searchTestSemester=' + searchTestSemester + '&searchSubject=' + searchSubject + '&searchUnit=' + searchUnit + '&searchLesson=' + searchLesson + '&searchTeacherId=' + searchTeacherId + '&teacherName=' + teacherName + '&searchDateFrom=' + searchDateFrom + '&searchDateTo=' + searchDateTo + '&searchSource=' + searchSource + '&searchStatus=' + searchStatus + '&searchTestType=' + searchTestType + '&searchAddedBy=' + searchAddedBy+ '&searchJob=' + searchJob + '&searchPaid=' + searchPaid);
  }

  addEditTest(data) {

    return this.http.post<any>(this._url + 'addEditTest', data);
  }

  copyTest(test_id:string | number) {
    return this.http.get<any>(this.data_transfer_url + 'testCopy&id=' + test_id);
  }

  addEditQuestion(data) {

    return this.http.post<any>(this._url + 'addEditQuestion', data);
  }

  generateTestRequestPdf(data) {

    // return this.http.post<any>(this._url + 'generateTestRequestPdf',data,{ responseType: 'blob'});
  }

  uploadAttachment(data) {

    return this.http.post<any>(this._url + 'uploadAttachment', data);
  }

  deleteAttachment(data) {
    return this.http.post<any>(this._url + 'deleteAttachment', data);
  }

  deleteTest(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteTest', body);
  }

  getQuestionsWithTestID(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getQuestionsWithTestID', body);
  }

  deleteQuestion(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteQuestion', body);
  }

  deleteSupQuestionByParent(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteSupQuestionByParent', body);
  }

  deleteQuestionOption(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteQuestionOption', body);
  }

  deleteMatchQuestionsItem(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteMatchQuestionsItem', body);
  }

  removeMatchQuestionsImage(data, image_type) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'removeMatchQuestionsImage&image_type=' + image_type, body);
  }

  deleteSortQuestionsWord(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteSortQuestionsWord', body);
  }

  deleteCompleteQuestionsWord(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteCompleteQuestionsWord', body);
  }

  getOneTest(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getOneTest', body);
  }

  getTestDetailsInfo(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getTestDetailsInfo', body);
  }

}
