import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class CountriesService {

    private _url: string = this.commonService._hostName + 'admin-api-v1/countries.php?action=';

    constructor(public http: HttpClient, public commonService: CommonService) { }

    getSomeCountries(start, aItemsPerPage, sort, type, searchName) {
        return this.http.get<any>(this._url + 'getSomeCountries&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchName=' + searchName);
    }

    getSearchCountriesCount(sort, type, searchName) {
        return this.http.get<any>(this._url + 'getSearchCountriesCount&sort=' + sort + '&type=' + type + '&searchName=' + searchName);
    }

    getCountriesCount() {
        return this.http.get<any>(this._url + 'getCountriesCount');
    }

    addEditCountry(formData) {
        return this.http.post<any>(this._url + 'addEditCountry', formData);
    }

    deleteCountry(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteCountry', body);
    }

    getOneCountry(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getOneCountry', body);
    }


    getCountryByParentID(data){
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getCountryByParentID', body);

    }

}
