import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpParams } from '@angular/common/http';
import { UserService } from './_services/user.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

    constructor(private injector: Injector) { }

    intercept(req, next) {
      let currentTime = new Date().getTime().toString();
      let params = new HttpParams({ fromString: req.params.toString() }).set(
        "time",
        currentTime);
        let authService = this.injector.get(UserService);
        let tokenizedReq = req.clone({

            setHeaders: { Authorization: `Bearer ${authService.getToken()}` },
            params:params
            // setHeaders: { Authorization: `${authService.getToken()}` }

        })

        return next.handle(tokenizedReq);

    }

}
