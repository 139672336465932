import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class PrintableDataService {

    private _url: string = this.commonService._hostName + 'admin-api-v1/printable-data.php?action=';
    private user_id;
    constructor(public http: HttpClient, public commonService: CommonService) {
        var currentUser = localStorage.getItem('currentUser');
        var retrievedObject = JSON.parse(currentUser);
        this.user_id = retrievedObject.id;
     }

    getSomePrintableData(start, aItemsPerPage, sort, type, searchName,searchCountry,searchStage) {
        return this.http.get<any>(this._url + 'getSomePrintableData&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchName=' + searchName+ '&searchCountry=' + searchCountry+ '&searchStage=' + searchStage);
    }


    getSearchPrintableDataCount(sort, type, searchName,searchCountry,searchStage) {
        return this.http.get<any>(this._url + 'getSearchPrintableDataCount&sort=' + sort + '&type=' + type + '&searchName=' + searchName+ '&searchCountry=' + searchCountry+ '&searchStage=' + searchStage);
    }

    getPrintableDataCount() {
        return this.http.get<any>(this._url + 'getPrintableDataCount');
    }



    addEditPrintableData(formData) {
        return this.http.post<any>(this._url + 'addEditPrintableData', formData);
    }

    deletePrintableData(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deletePrintableData', body);
    }

    getOnePrintableData(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getOnePrintableData', body);
    }




}
