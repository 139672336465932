import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';


import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class ReviewNotesService
{

    private _url: string = this.commonService._hostName + 'admin-api-v1/final_review_notes.php?action=';
    private user_id;
    constructor(public http: HttpClient, public commonService: CommonService) {
        var currentUser = localStorage.getItem('currentUser');
        var retrievedObject = JSON.parse(currentUser);
        this.user_id = retrievedObject.id;
     }

    getSomeNotes(start, aItemsPerPage, sort, type, searchTitle,searchCountry,searchStage,searchGrade,searchSemester,searchSubject,searchUnit,searchLesson,searchTeacherId,teacherName,searchDateFrom,searchDateTo,searchSource,QuestionStatus) {
        return this.http.get<any>(this._url + 'getSomeNotes&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchTitle=' + searchTitle+ '&searchCountry=' + searchCountry+ '&searchStage=' + searchStage+ '&searchGrade=' + searchGrade+ '&searchSemester=' + searchSemester+ '&searchSubject=' + searchSubject+ '&searchUnit=' + searchUnit+ '&searchLesson=' + searchLesson+ '&searchTeacherId=' + searchTeacherId+ '&teacherName=' + teacherName+ '&searchDateFrom=' + searchDateFrom+ '&searchDateTo=' + searchDateTo+ '&searchSource=' + searchSource+'&QuestionStatus='+ QuestionStatus + '&user_id=' + this.user_id);
    }

    getSearchNotesCount(sort, type, searchTitle,searchCountry,searchStage,searchGrade,searchSemester,searchSubject,searchUnit,searchLesson,searchTeacherId,teacherName,searchDateFrom,searchDateTo,searchSource,QuestionStatus) {
        return this.http.get<any>(this._url + 'getSearchNotesCount&sort=' + sort + '&type=' + type + '&searchTitle=' + searchTitle+ '&searchCountry=' + searchCountry+ '&searchStage=' + searchStage+ '&searchGrade=' + searchGrade+ '&searchSemester=' + searchSemester+ '&searchSubject=' + searchSubject+ '&searchUnit=' + searchUnit+ '&searchLesson=' + searchLesson+ '&searchTeacherId=' + searchTeacherId+ '&teacherName=' + teacherName+ '&searchDateFrom=' + searchDateFrom+ '&searchDateTo=' + searchDateTo+ '&searchSource=' + searchSource+ '&QuestionStatus=' + QuestionStatus );
    }

    getNotesCount() {
        return this.http.get<any>(this._url + 'getNotesCount');
    }



    getAllCategories(type='notes',subject_id='', branch_id='') {
        return this.http.get<any>(this._url + 'getAllCategories&type=' + type+'&subject_id=' + subject_id + '&branch_id=' + branch_id);
    }
    addEditNotes(data) {

        return this.http.post<any>(this._url + 'addEditNotes', data);
    }
    generateTestRequestPdf(data) {

        // return this.http.post<any>(this._url + 'generateTestRequestPdf',data,{ responseType: 'blob'});
    }

    uploadAttachment(data) {

        return this.http.post<any>(this._url + 'uploadAttachment', data);
    }

    deleteAttachment(data) {
        return this.http.post<any>(this._url + 'deleteAttachment', data);
    }

    deleteNote(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteNote', body);
    }







    getOneTest(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getOneTest', body);
    }
    getTestDetailsInfo(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getTestDetailsInfo', body);
    }

    activationNotes(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'toggleActivationQuestions', body);
    }

}
