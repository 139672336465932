import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';


import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class ArticlesService {

    private _url: string = this.commonService._hostName + 'admin-api-v1/articles.php?action=';

    constructor(public http: HttpClient, public commonService: CommonService) { }

    getSomeArticles(start, aItemsPerPage, sort, type, searchTitle,searchCategory) {
        return this.http.get<any>(this._url + 'getSomeArticles&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchTitle=' + searchTitle+ '&searchCategory=' + searchCategory);
    }

    getSearchArticlesCount(sort, type, searchTitle,searchCategory) {
        return this.http.get<any>(this._url + 'getSearchArticlesCount&sort=' + sort + '&type=' + type + '&searchTitle=' + searchTitle+ '&searchCategory=' + searchCategory);
    }

    getArticlesCount() {
        return this.http.get<any>(this._url + 'getArticlesCount');
    }


    getSomeArticlesComments(start, aItemsPerPage, sort, type, searchId,searchName,searchDateFrom,searchDateTo,searchStatus) {
        return this.http.get<any>(this._url + 'getSomeArticlesComments&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchId=' + searchId+ '&searchName=' + searchName+ '&searchDateFrom=' + searchDateFrom+ '&searchDateTo=' + searchDateTo+ '&searchStatus=' + searchStatus);
    }

    getSearchArticlesCommentsCount(sort, type, searchId,searchName,searchDateFrom,searchDateTo,searchStatus) {
        return this.http.get<any>(this._url + 'getSearchArticlesCommentsCount&sort=' + sort + '&type=' + type + '&searchId=' + searchId+ '&searchName=' + searchName+ '&searchDateFrom=' + searchDateFrom+ '&searchDateTo=' + searchDateTo+ '&searchStatus=' + searchStatus);
    }

    getArticlesCommentsCount() {
        return this.http.get<any>(this._url + 'getArticlesCommentsCount');
    }


    getAllCategories(type='article') {
        return this.http.get<any>(this._url + 'getAllCategories&type=' + type);
    }

    addEditArticle(data) {
        return this.http.post<any>(this._url + 'addEditArticle', data);
    }

    deletePage(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deletePage', body);
    }
    deleteItems(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteItems', body);
    }

    getOneArticle(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getOneArticle', body);
    }

    checkIfSlugExistInArticles(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'checkIfSlugExistInArticles', body);
    }

    deleteComment(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteComment', body);
    }

    activeComment(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'activeComment', body);
    }


}
