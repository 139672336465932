import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


import {CommonService} from '../../../../_services/common.service';

@Injectable()
export class SubscriptionCostsService {

  private _url: string = this.commonService._hostName + 'admin-api-v1/subscription_costs.php?action=';
  private user_id;

  constructor(public http: HttpClient, public commonService: CommonService) {
    var currentUser = localStorage.getItem('currentUser');
    var retrievedObject = JSON.parse(currentUser);
    this.user_id = retrievedObject.id;
  }

  getSomeSubscriptionCosts(start, aItemsPerPage, sort, type, searchName, searchCountry) {
    return this.http.get<any>(this._url + 'getSomeSubscriptionCosts&start=' + start +
      '&aItemsPerPage=' + aItemsPerPage +
      '&sort=' + sort +
      '&type=' + type +
      '&searchName=' + searchName +
      '&searchCountry=' + searchCountry
    );
  }


  getSearchSubscriptionCostsCount(sort, type, searchName, searchCountry) {
    return this.http.get<any>(this._url + 'getSearchSubscriptionCostsCount&sort=' + sort +
      '&type=' + type +
      '&searchName=' + searchName +
      '&searchCountry=' + searchCountry
    );
  }

  getSubscriptionCostsCount() {
    return this.http.get<any>(this._url + 'getSubscriptionCostsCount'
    );
  }

  getAllCountries() {
    return this.http.get<any>(this._url + 'getAllCountries&user_id=' + this.user_id);
  }

  addEditSubscriptionCost(formData) {
    return this.http.post<any>(this._url + 'addEditSubscriptionCost', formData);
  }

  addEditSubscriptionContent(formData) {
    return this.http.post<any>(this._url + 'addEditSubscriptionContent', formData);
  }

  addEditSubscriptionSteps(formData) {
    return this.http.post<any>(this._url + 'addEditSubscriptionSteps', formData);
  }

  addEditSubscriptionVideos(formData) {
    return this.http.post<any>(this._url + 'addEditSubscriptionVideos', formData);
  }

  deleteConfirm(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteConfirm', body);
  }

  removeSubscriptionContent(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'removeSubscriptionContent', body);
  }

  removeSubscriptionSteps(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'removeSubscriptionSteps', body);
  }

  removeSubscriptionVideo(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'removeSubscriptionVideo', body);
  }

  getOneSubscriptionCost(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getOneSubscriptionCost', body);
  }

  getAllSubscriptionCostContent() {

    return this.http.get<any>(this._url + 'getAllSubscriptionCostContent');
  }

  getAllSubscriptionCostSteps() {

    return this.http.get<any>(this._url + 'getAllSubscriptionCostSteps');
  }

  getAllSubscriptionCostVideos() {

    return this.http.get<any>(this._url + 'getAllSubscriptionCostVideos');
  }

  getStageByParentID(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getStageByParentID', body);

  }

  checkThisCost(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'checkThisCost', body);

  }

}
