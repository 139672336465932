import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class GradesService {

    private _url: string = this.commonService._hostName + 'admin-api-v1/grades.php?action=';
    private user_id;
    constructor(public http: HttpClient, public commonService: CommonService) {
        var currentUser = localStorage.getItem('currentUser');
        var retrievedObject = JSON.parse(currentUser);
        this.user_id = retrievedObject.id;
     }

    getSomeGrades(start, aItemsPerPage, sort, type, searchName,searchCountry,searchStage) {
        return this.http.get<any>(this._url + 'getSomeGrades&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchName=' + searchName+ '&searchCountry=' + searchCountry+ '&searchStage=' + searchStage);
    }


    getSearchGradesCount(sort, type, searchName,searchCountry,searchStage) {
        return this.http.get<any>(this._url + 'getSearchGradesCount&sort=' + sort + '&type=' + type + '&searchName=' + searchName+ '&searchCountry=' + searchCountry+ '&searchStage=' + searchStage);
    }

    getGradesCount() {
        return this.http.get<any>(this._url + 'getGradesCount');
    }



    addEditGrade(formData) {
        return this.http.post<any>(this._url + 'addEditGrade', formData);
    }

    deleteGrade(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteGrade', body);
    }

    getOneGrade(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getOneGrade', body);
    }


    getGradeByParentID(data){
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getGradeByParentID', body);

    }

}
