import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


import {CommonService} from '../../../../_services/common.service';

@Injectable({
  providedIn: 'root'
})
export class DiscountCodesService {

  private _url: string = this.commonService._hostName + 'admin-api-v1/subjects_costs.php?action=';
  private user_id;

  constructor(public http: HttpClient, public commonService: CommonService) {
    var currentUser = localStorage.getItem('currentUser');
    var retrievedObject = JSON.parse(currentUser);
    this.user_id = retrievedObject.id;
  }

  getSomeSubjectsCosts(start, aItemsPerPage, sort, type, searchCode, searchCountry) {
    return this.http.get<any>(this._url + 'getSomeCodes&start=' + start +
      '&aItemsPerPage=' + aItemsPerPage +
      '&sort=' + sort +
      '&type=' + type +
      '&searchCode=' + searchCode +
      '&searchCountry=' + searchCountry
    );
  }


  getSearchSubjectsCostsCount(sort, type, searchCode, searchCountry) {
    return this.http.get<any>(this._url + 'getSearchCodesCount&sort=' + sort +
      '&type=' + type +
      '&searchCode=' + searchCode +
      '&searchCountry=' + searchCountry
    );
  }

  getSubjectsCostsCount() {
    return this.http.get<any>(this._url + 'getCodesCount'
    );
  }

  getAllCountries() {
    return this.http.get<any>(this._url + 'getAllCountries&user_id=' + this.user_id);
  }

  addEditSubjectCost(formData) {
    return this.http.post<any>(this._url + 'addEditSubjectCost', formData);
  }

  addEditSubjectContent(formData) {
    return this.http.post<any>(this._url + 'addEditSubjectContent', formData);
  }

  addEditSubjectSteps(formData) {
    return this.http.post<any>(this._url + 'addEditSubjectSteps', formData);
  }

  addEditSubscriptionVideos(formData) {
    return this.http.post<any>(this._url + 'addEditSubscriptionVideos', formData);
  }

  deleteConfirm(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteConfirm', body);
  }

  deleteCodeConfirm(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteCodeConfirm', body);
  }

  removeSubscriptionContent(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'removeSubscriptionContent', body);
  }

  removeSubscriptionSteps(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'removeSubscriptionSteps', body);
  }

  removeSubscriptionVideo(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'removeSubscriptionVideo', body);
  }

  getOneSubscriptionCost(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getOneSubscriptionCost', body);
  }

  getAllSubscriptionCostContent() {

    return this.http.get<any>(this._url + 'getAllSubscriptionCostContent');
  }

  getAllSubscriptionCostSteps() {

    return this.http.get<any>(this._url + 'getAllSubscriptionCostSteps');
  }

  getAllSubscriptionCostVideos() {

    return this.http.get<any>(this._url + 'getAllSubscriptionCostVideos');
  }

  getStageByParentID(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getStageByParentID', body);

  }

checkThisCost(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'checkThisCost', body);

}

  addDiscountCode(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'addDiscountCode', body);
  }
}

