import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';


import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class RequestsService {

  private _url: string = this.commonService._hostName + 'admin-api-v1/teacher_credits.php?action=';

    constructor(public http: HttpClient, public commonService: CommonService) { }


    getSomeRequests(start, aItemsPerPage, searchName, searchDateFrom, searchDateTo) {
      return this.http.get<any>(this._url + 'getSomeRequests&'+
        'start=' + start+
        '&aItemsPerPage=' + aItemsPerPage+
        '&searchName=' + searchName+
      '&startDate=' + searchDateFrom +
      '&endDate=' + searchDateTo
      );
  }

  getRequestsCount(searchName, searchDateFrom, searchDateTo) {
    return this.http.get<any>(this._url + 'getRequestsCount&' +
      'searchName=' + searchName+
      '&startDate=' + searchDateFrom +
      '&endDate=' + searchDateTo
    );
}

getSearchRequestsCount(searchName, searchDateFrom, searchDateTo) {
  return this.http.get<any>(this._url + 'getSearchRequestsCount&'+
    'searchName=' + searchName+
      '&startDate=' + searchDateFrom +
      '&endDate=' + searchDateTo
  );
}


  withdrawForTeachers(data) {
    console.log(data);
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'withdrawForTeachers', body);
}

getOneRequest(id){
  return this.http.get<any>(this._url + 'getOneRequest&id=' + id);
}
}

