import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';


import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class NotificationsService {

    private _url: string = this.commonService._hostName + 'admin-api-v1/admin_notifications.php?action=';

    constructor(public http: HttpClient, public commonService: CommonService) { }

    getSomeNots(start, aItemsPerPage, sort, type, searchTitle) {
        return this.http.get<any>(this._url + 'getSomeNots&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchTitle=' + searchTitle);
    }

    getSearchNotsCount(sort, type, searchTitle) {
        return this.http.get<any>(this._url + 'getSearchNotsCount&sort=' + sort + '&type=' + type + '&searchTitle=' + searchTitle);
    }

    getNotCount() {
        return this.http.get<any>(this._url + 'getNotCount');
    }

    addEditNot(data) {
        return this.http.post<any>(this._url + 'addEditNot', data);
    }

    deleteNot(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteNot', body);
    }

    getOneNot(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getOneNot', body);
    }

}
