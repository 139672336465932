import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {CommonService} from '../_services/common.service';

@Injectable()
export class UserService {

  private _url: string = this.commonService._hostName + 'admin-api-v1/users.php?action=';

  constructor(public http: HttpClient, public commonService: CommonService) {
  }


  signIn(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'signIn', body);
  }

  forgetPassword(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'userForgetPassword', body);
  }

  loggedIn() {
    return !!localStorage.getItem('token');
  }

  getAllRegister() {
    return this.http.get<any>(this._url + 'getAllRegisterUsers');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////

  getUsersLevels() {
    return this.http.get<any>(this._url + 'getUsersLevels');
  }

  getSomeUsers(
    userType,
    start,
    aItemsPerPage,
    sort,
    type,
    searchName,
    searchEmail,
    searchUserPhone,
    searchDateFrom,
    searchDateTo,
    searchUserStatus,
    searchUserType,
    accountStatus,
    searchId = '',
    searchCountry,
    searchAccountType,
    searchStage,
    searchGrade
  ) {
    return this.http.get<any>(this._url + 'getSomeUsers&start=' + start +
      '&aItemsPerPage=' + aItemsPerPage +
      '&sort=' + sort +
      '&type=' + type +
      '&searchName=' + searchName +
      '&searchEmail=' + searchEmail +
      '&userType=' + userType +
      '&searchUserPhone=' + searchUserPhone +
      '&searchDateFrom=' + searchDateFrom +
      '&searchDateTo=' + searchDateTo +
      '&searchUserStatus=' + searchUserStatus +
      '&searchUserType=' + searchUserType +
      '&accountStatus=' + accountStatus +
      '&searchId=' + searchId +
      '&searchCountry=' + searchCountry +
      '&searchAccountType=' + searchAccountType +
      '&searchStage=' + searchStage +
      '&searchGrade=' + searchGrade
    );
  }

  getSearchUsersCount(
    userType,
    sort,
    type,
    searchName,
    searchEmail,
    searchUserPhone,
    searchDateFrom,
    searchDateTo,
    searchUserStatus,
    searchUserType,
    accountStatus,
    searchId = '',
    searchCountry,
    searchAccountType,
    searchStage,
    searchGrade
  ) {
    return this.http.get<any>(this._url + 'getSearchUsersCount&sort=' + sort +
      '&type=' + type +
      '&searchName=' + searchName +
      '&searchEmail=' + searchEmail +
      '&userType=' + userType +
      '&searchUserPhone=' + searchUserPhone +
      '&searchDateFrom=' + searchDateFrom +
      '&searchDateTo=' + searchDateTo +
      '&searchUserStatus=' + searchUserStatus +
      '&searchUserType=' + searchUserType +
      '&accountStatus=' + accountStatus +
      '&searchId=' + searchId +
      '&searchCountry=' + searchCountry +
      '&searchAccountType=' + searchAccountType +
      '&searchStage=' + searchStage +
      '&searchGrade=' + searchGrade
    );
  }

  getUsersCount(userType, provider_id = '') {
    return this.http.get<any>(this._url + 'getUsersCount&userType=' + userType + '&provider_id=' + provider_id);
  }

  addEditUser(formData) {
    return this.http.post<any>(this._url + 'addEditUser', formData);
  }

  addSubscription(Data) {
    let body = JSON.stringify(Data);
    return this.http.post<any>(this._url + 'addSubscription', body);
  }

  deleteUser(userType, data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteUser&userType=' + userType, body);
  }

  removeSubscription(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'removeSubscription', body);
  }

  deleteStudentsParents(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteStudentsParents', body);
  }

  removeTeacher(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'removeTeacher', body);
  }

  deleteAdminRole(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteAdminRole', body);
  }

  deleteOneUserCar(user_id, data) {
    let obj = {'user_id': user_id, 'car_id': data};
    return this.http.post<any>(this._url + 'deleteOneUserCar', obj);
  }

  getSearchUsersByType(query, user_type = '') {
    return this.http.post<any>(this._url + 'getSearchUsersByType&query=' + query + '&user_type=' + user_type, null);
  }

  getSearchUsersByTypeForSubscription(query) {
    return this.http.post<any>(this._url + 'getSearchUsersByTypeForSubscription&query=' + query, null);
  }

  getOneUser(userType, data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getOneUser&userType=' + userType, body);
  }

  getAllProviderDrivers(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getAllProviderDrivers', body);
  }

  addingDriverToOrder(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'addingDriverToOrder', body);
  }

  editOrderVisit(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'editOrderVisit', body);
  }

  acceptedVisitsEdit(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'acceptedVisitsEdit', body);
  }

  getUserDetailsInfo(id) {
    return this.http.get<any>(this._url + 'getUserDetailsInfo&id=' + id);
  }


  chickEmail(email) {
    return this.http.get<any>(this._url + 'chickEmail&email=' + email);
  }

  chickMobile(mobile) {
    return this.http.get<any>(this._url + 'chickMobile&mobile=' + mobile);
  }


  getAllSchoolYears(country_id) {
    return this.http.get<any>(this._url + 'getAllSchoolYears&country_id=' + country_id);
  }

  getSubscriptionCosts(subscription_type, country_id) {
    return this.http.get<any>(this._url + 'getSubscriptionCosts&country_id=' + country_id + '&subscription_type=' + subscription_type);
  }

  getAllAdmins() {
    return this.http.get<any>(this._url + 'getSomeUsers&start=0' + '&aItemsPerPage=5000' + '&sort=id' + '&type=ASC' + '&userType=admins');
  }

  deleteTeacherFile(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteTeacherFile', body);
  }

  addEditProfileTeacher(formData) {
    return this.http.post<any>(this._url + 'addEditProfileTeacher', formData);
  }


  getAllSubjectsSubscriptionCosts(user_id, grade_id, user_type) {
    return this.http.get<any>(this._url + 'getAllSubjectsSubscriptionCosts&user_id=' + user_id + '&grade_id=' + grade_id + '&user_type=' + user_type);
  }
}
