import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class SettingsService {

    private _url: string = this.commonService._hostName + 'admin-api-v1/settings.php?action=';

    constructor(private router: Router,
      public http: HttpClient, public commonService: CommonService) { }

    getAllSettings() {
      let response = this.http.get<any>(this._url + 'getAllSettings');
    response.subscribe( data => {
      console.log(data.is_disabled && !data.success);
      if(data.is_disabled && !data.success){
        localStorage.removeItem("currentUser");
        localStorage.removeItem("token");
        this.router.navigate(['/login']);
      }
    })
    return response;
    }

    editSettings(data) {
        return this.http.post<any>(this._url + 'editSettings', data);
    }

}
