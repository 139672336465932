import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType} from '@angular/common/http';

import {CommonService} from '../../../../_services/common.service';

@Injectable()
export class CoursesService {
  private _url: string =
    this.commonService._hostName + 'admin-api-v1/courses.php?action=';
  private user_id;

  constructor(public http: HttpClient, public commonService: CommonService) {
    var currentUser = localStorage.getItem('currentUser');
    var retrievedObject = JSON.parse(currentUser);
    this.user_id = retrievedObject.id;
  }

  getSomeCourses(
    start,
    aItemsPerPage,
    sort,
    type,
    searchName,
    searchCountry,
    searchStage,
    searchGrade,
    searchSemester,
    searchSubject,
    searchStatus,
    searchSource = '0'

  ) {
    return this.http.get<any>(
      this._url +
      'getSomeCourses&start=' +
      start +
      '&aItemsPerPage=' +
      aItemsPerPage +
      '&sort=' +
      sort +
      '&type=' +
      type +
      '&searchName=' +
      searchName +
      '&searchCountry=' +
      searchCountry +
      '&searchStage=' +
      searchStage +
      '&searchGrade=' +
      searchGrade +
      '&searchSemester=' +
      searchSemester +
      '&searchSubject=' +
      searchSubject +
      '&searchStatus=' +
      searchStatus +
      '&searchSource=' +
      searchSource
    );
  }

  getSearchCoursesCount(
    sort,
    type,
    searchName,
    searchCountry,
    searchStage,
    searchGrade,
    searchSemester,
    searchSubject,
    searchStatus,
    searchSource = '0'
  ) {
    return this.http.get<any>(
      this._url +
      'getSearchCoursesCount&sort=' +
      sort +
      '&type=' +
      type +
      '&searchName=' +
      searchName +
      '&searchCountry=' +
      searchCountry +
      '&searchStage=' +
      searchStage +
      '&searchGrade=' +
      searchGrade +
      '&searchSemester=' +
      searchSemester +
      '&searchSubject=' +
      searchSubject +
      '&searchStatus=' +
      searchStatus +
      '&searchSource=' +
      searchSource
    );
  }

  getCoursesCount(
    searchName,
    searchCountry,
    searchStage,
    searchGrade,
    searchSemester,
    searchSubject,
    searchStatus,
    searchSource = '0'
  ) {
    return this.http.get<any>(
      this._url +
      'getCoursesCount&searchName=' +
      searchName +
      '&searchCountry=' +
      searchCountry +
      '&searchStage=' +
      searchStage +
      '&searchGrade=' +
      searchGrade +
      '&searchSemester=' +
      searchSemester +
      '&searchSubject=' +
      searchSubject +
      '&searchStatus=' +
      searchStatus +
      '&searchSource=' +
      searchSource
    );
  }

  addEditCourse(formData) {
    return this.http.post<any>(this._url + 'addEditCourse', formData);
  }

  addEditVideoOfCourse(formData) {
    return this.http.post<any>(this._url + 'addEditVideoOfCourse', formData);
  }

  deleteCourse(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteCourse', body);
  }

  deleteVideoInCourse(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteVideoInCourse', body);
  }

  updateCommentById(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'updateCommentById', body);
  }

  deleteCommentsInVideo(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteCommentsInVideo', body);
  }

  activateCommentsInVideo(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'activateCommentsInVideo', body);
  }

  getOneCourse(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getOneCourse', body);
  }

  getOneVideoCourse(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getOneVideoCourse', body);
  }

  getCourseByParentID(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getCourseByParentID', body);
  }

  uploadAttachment(data) {
    return this.http.post<any>(this._url + 'uploadAttachment', data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  /** Return distinct message for sent, upload progress, & response events */
  getEventMessage(event: HttpEvent<any>, file: File) {
    console.log('event===>', event);
    console.log('event.type===>', event.type);
    console.log('HttpEventType.Sent==>', HttpEventType.Sent);
    console.log('HttpEventType.UploadProgress==>', HttpEventType.UploadProgress);
    console.log('HttpEventType.Response==>', HttpEventType.Response);
    switch (event.type) {
      case HttpEventType.Sent:
        return `Uploading file "${file.name}" of size ${file.size}.`;

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round(100 * event.loaded / event.total);
        return `File "${file.name}" is ${percentDone}% uploaded.`;

      case HttpEventType.Response:
        let _obj = {
          data: HttpEventType.Response,
          msg: `File "${file.name}" was completely uploaded!`
        };
        return _obj;

      default:
        return `File "${file.name}" surprising upload event: ${event.type}.`;
    }
  }

  deleteAttachment(data, course_id?:any) {
    data.append('course_id', course_id);
    return this.http.post<any>(this._url + 'deleteAttachment&course_id=' + course_id, data);
  }

  deleteVideoTests(data) {
    return this.http.post<any>(this._url + 'deleteVideoTests', data);
  }

  deleteAttachFile(data) {
    return this.http.post<any>(this._url + 'deleteAttachFile', data);
  }

  getAllUnitExerciseByLessonId(data: any) {
    return this.http.post<any>(
      this._url + 'getAllUnitExerciseByLessonId',
      data
    );
  }

  getAllUnitExerciseInSubjectSemester(data: any) {
    return this.http.post<any>(
      this._url + 'getAllUnitExerciseInSubjectSemester',
      data
    );
  }

  getSomeVideosInCourses(
    course_id,
    start,
    aItemsPerPage,
    sort,
    type,
    searchName,
    searchCountry
  ) {
    return this.http.get<any>(
      this._url +
      'getSomeVideosInCourses&course_id=' +
      course_id +
      '&start=' +
      start +
      '&aItemsPerPage=' +
      aItemsPerPage +
      '&sort=' +
      sort +
      '&type=' +
      type +
      '&searchName=' +
      searchName +
      '&searchCountry=' +
      searchCountry
    );
  }

  getSearchVideosCoursesCount(
    course_id,
    sort,
    type,
    searchName,
    searchCountry
  ) {
    return this.http.get<any>(
      this._url +
      'getSearchVideosCoursesCount&course_id=' +
      course_id +
      '&sort=' +
      sort +
      '&type=' +
      type +
      '&searchName=' +
      searchName +
      '&searchCountry=' +
      searchCountry
    );
  }

  getVideosInCoursesCount(course_id: any, searchName: any) {
    return this.http.get<any>(
      this._url +
      'getVideosInCoursesCount&course_id=' +
      course_id +
      '&search_name=' +
      searchName
    );
  }

  getAllCommentsInVideosById(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getAllCommentsInVideosById', body);
  }

  getCountCommentsInVideos(video_id: any, searchName: any) {
    return this.http.get<any>(
      this._url +
      'getCountCommentsInVideos&video_id=' +
      video_id +
      '&searchName=' +
      searchName
    );
  }

  getSearchCommentsCountVideos(
    video_id,
    sort,
    type,
    searchName,
    searchCountry
  ) {
    return this.http.get<any>(
      this._url +
      'getSearchCommentsCountVideos&video_id=' +
      video_id +
      '&sort=' +
      sort +
      '&type=' +
      type +
      '&searchName=' +
      searchName +
      '&searchCountry=' +
      searchCountry
    );
  }

  getSomeCommentsInVedios(
    video_id,
    start,
    aItemsPerPage,
    sort,
    type,
    searchName,
    searchCountry
  ) {
    return this.http.get<any>(
      this._url +
      'getSomeCommentsInVedios&video_id=' +
      video_id +
      '&start=' +
      start +
      '&aItemsPerPage=' +
      aItemsPerPage +
      '&sort=' +
      sort +
      '&type=' +
      type +
      '&searchName=' +
      searchName +
      '&searchCountry=' +
      searchCountry
    );
  }

  getCountInquiriesInVideos(video_id: any, searchName: any, searchUserName: any) {
    return this.http.get<any>(
      this._url +
      'getCountInquiriesInVideos&video_id=' +
      video_id +
      '&searchName=' +
      searchName +
      '&searchUserName=' +
      searchUserName
    );
  }

  getSearchInquiriesCountVideos(
    video_id,
    sort,
    type,
    searchName,
    searchUserName
  ) {
    return this.http.get<any>(
      this._url +
      'getSearchInquiriesCountVideos&video_id=' +
      video_id +
      '&sort=' +
      sort +
      '&type=' +
      type +
      '&searchName=' +
      searchName +
      '&searchUserName=' +
      searchUserName
    );
  }

  getSomeInquiriesInVedios(
    video_id,
    start,
    aItemsPerPage,
    sort,
    type,
    searchName,
    searchUserName
  ) {
    return this.http.get<any>(
      this._url +
      'getSomeInquiriesInVedios&video_id=' +
      video_id +
      '&start=' +
      start +
      '&aItemsPerPage=' +
      aItemsPerPage +
      '&sort=' +
      sort +
      '&type=' +
      type +
      '&searchName=' +
      searchName +
      '&searchUserName=' +
      searchUserName
    );
  }
}
