import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';


import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class FrequentlyQuestionsService {

    private _url: string = this.commonService._hostName + 'admin-api-v1/frequently_questions.php?action=';

    constructor(public http: HttpClient, public commonService: CommonService) { }

    getSomeFrequentlyQuestions(start, aItemsPerPage, sort, type, searchName) {
        return this.http.get<any>(this._url + 'getSomeFrequentlyQuestions&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchName=' + searchName);
    }

    getSearchFrequentlyQuestionsCount(sort, type, searchName) {
        return this.http.get<any>(this._url + 'getSearchFrequentlyQuestionsCount&sort=' + sort + '&type=' + type + '&searchName=' + searchName);
    }

    getFrequentlyQuestionsCount() {
        return this.http.get<any>(this._url + 'getFrequentlyQuestionsCount');
    }

    addEditFrequentlyQuestion(formData) {
        return this.http.post<any>(this._url + 'addEditFrequentlyQuestion', formData);
    }

    deleteFrequentlyQuestion(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteFrequentlyQuestion', body);
    }

    getOneQuestion(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getOneQuestion', body);
    }

}
