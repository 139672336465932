import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';


import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class SubjectsService {

    private _url: string = this.commonService._hostName + 'admin-api-v1/subjects.php?action=';
    private user_id;
    constructor(public http: HttpClient, public commonService: CommonService) {
        var currentUser = localStorage.getItem('currentUser');
        var retrievedObject = JSON.parse(currentUser);
        this.user_id = retrievedObject.id;
    }

    getSomeSubjects(start, aItemsPerPage, sort, type, searchName,searchCountry,searchStage,searchGrade,searchSemester,searchSpecialize) {
        return this.http.get<any>(this._url + 'getSomeSubjects&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchName=' + searchName+ '&searchCountry=' + searchCountry+ '&searchStage=' + searchStage+ '&searchGrade=' + searchGrade+ '&searchSemester=' + searchSemester+ '&searchSpecialize=' + searchSpecialize);
    }

    getSearchSubjectsCount(sort, type, searchName,searchCountry,searchStage,searchGrade,searchSemester,searchSpecialize) {
        return this.http.get<any>(this._url + 'getSearchSubjectsCount&sort=' + sort + '&type=' + type + '&searchName=' + searchName+ '&searchCountry=' + searchCountry+ '&searchStage=' + searchStage+ '&searchGrade=' + searchGrade+ '&searchSemester=' + searchSemester+ '&searchSpecialize=' + searchSpecialize);
    }

    getSubjectsCount() {
        return this.http.get<any>(this._url + 'getSubjectsCount');
    }

    addEditSubject(formData) {
        return this.http.post<any>(this._url + 'addEditSubject', formData);
    }
    getAllCountries() {
        return this.http.get<any>(this._url + 'getAllCountries&user_id=' + this.user_id);
    }


    deleteSubject(data) {
      let body = JSON.stringify(data);
      return this.http.post<any>(this._url + 'deleteSubject', body);
  }

  deleteOneBranch(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteBranch', body);
}

    getOneSubject(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getOneSubject', body);
    }


    getAllFieldsOfStudy(grade_id){
      return this.http.get<any>(this._url + 'getAllParentFieldsOfStudy&grade_id=' + grade_id);
    }

    getAllChildBytFieldsOfStudyId(parent_id){
      return this.http.get<any>(this._url + 'getAllChildBytFieldsOfStudyId&parent_id='+parent_id);
    }


    getBranchesBySubjectId(subject_id){
      return this.http.get<any>(this._url + 'getBranchesBySubjectId&subject_id='+subject_id);
    }

}
