import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CommonService} from '../../../../_services/common.service';

@Injectable()
export class ShowEmailModalService {

  private _url: string =
    this.commonService._hostName + 'admin-api-v1/emails.php?action=';
  private user_id;

  constructor(public http: HttpClient, public commonService: CommonService) {
    var currentUser = localStorage.getItem('currentUser');
    var retrievedObject = JSON.parse(currentUser);
    this.user_id = retrievedObject.id;
  }

  getSomeEmailsByStageId(
    start,
    aItemsPerPage,
    searchName,
    searchEmail,
    searchStage
  ) {
    return this.http.get<any>(
      this._url +
      'getSomeEmailsByStageId&start=' +
      start +
      '&aItemsPerPage=' +
      aItemsPerPage +
      '&searchName=' +
      searchName +
      '&searchEmail=' +
      searchEmail +
      '&searchStage=' +
      searchStage
    );
  }

  getSearchEmailsCountByStageId(
    searchName,
    searchEmail,
    searchStage
  ) {
    return this.http.get<any>(
      this._url +
      'getSearchEmailsCountByStageId&searchName=' +
      searchName +
      '&searchEmail=' +
      searchEmail +
      '&searchStage=' +
      searchStage
    );
  }

  getEmailsCountByStageId(
    searchName,
    searchEmail,
    searchStage
  ) {
    return this.http.get<any>(
      this._url +
      'getEmailsCountByStageId&searchName=' +
      searchName +
      '&searchEmail=' +
      searchEmail +
      '&searchStage=' +
      searchStage
    );
  }


  deleteEmailsById(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteEmailsById', body);
  }
}
