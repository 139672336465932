import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class GroupsService {

    private _url: string = this.commonService._hostName + 'admin-api-v1/groups.php?action=';

    constructor(public http: HttpClient, public commonService: CommonService) { }

    getSomeGroups(start, aItemsPerPage, sort, type, searchId,searchName,searchDateFrom,searchDateTo,searchTeacherId,teacherName) {
        return this.http.get<any>(this._url + 'getSomeGroups&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchId=' + searchId+ '&searchName=' + searchName+ '&searchDateFrom=' + searchDateFrom+ '&searchDateTo=' + searchDateTo+ '&searchTeacherId=' + searchTeacherId+ '&teacherName=' + teacherName);
    }

    getSearchGroupsCount(sort, type, searchId,searchName,searchDateFrom,searchDateTo,searchTeacherId,teacherName) {
        return this.http.get<any>(this._url + 'getSearchGroupsCount&sort=' + sort + '&type=' + type + '&searchId=' + searchId+ '&searchName=' + searchName+ '&searchDateFrom=' + searchDateFrom+ '&searchDateTo=' + searchDateTo+ '&searchTeacherId=' + searchTeacherId+ '&teacherName=' + teacherName);
    }

    getGroupsCount() {
        return this.http.get<any>(this._url + 'getGroupsCount');
    }

    getOneGroup(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getOneGroup', body);
    }



    deleteOrder(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteOrder', body);
    }

}
