import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ShowEmailModalService} from './show-email-modal.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CommonService} from '../../../../_services/common.service';
import {Router} from '@angular/router';
import {SweetAlertService} from 'angular-sweetalert-service';

@Component({
  selector: 'app-show-email-modal',
  templateUrl: './show-email-modal.component.html',
  styleUrls: ['./show-email-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShowEmailModalComponent implements OnInit {

  @Input() dataModal;
  isDataAvailable: boolean;
  all_emails: any = [];
  noData: boolean;
  emailsCount: number;
  categoryForm: FormGroup;
  deleteForm: FormGroup;
  searchName: string = '';
  searchEmail: string = '';
  pagination_pages;
  resultObj = {};
  searchEmailsCount: number;
  current_page: number;
  previous;
  next;
  count;
  deleteMultiple: string = '';
  selectedOption: boolean = false;
  loadingImgPath: string = '';
  pageParam: any;
  catImgPath: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    public pageService: ShowEmailModalService,
    public commService: CommonService,
    public router: Router,
    private alertService: SweetAlertService,
    private fb: FormBuilder
  ) {
    this.categoryForm = fb.group({
      searchName: [null],
      searchEmail: [null],
      item: [null],
    });

    this.deleteForm = fb.group({
      deleteMultiple: [null],
    });

    this.loadingImgPath = this.commService._hostName + 'uploads/loading-1.gif';
    this.catImgPath = this.commService._hostName + 'uploads/stages/';
  }

  ngOnInit() {
    this.isDataAvailable = false;
    setTimeout(() => {
      this.getAllData();
    }, 1000);

  }

  getAllData() {

    this.pageParam = parseInt(this.pageParam) ? parseInt(this.pageParam) : 1;
    this.searchName = this.searchName ? this.searchName : '';
    this.searchEmail = this.searchEmail ? this.searchEmail : '';


    this.pageService
      .getEmailsCountByStageId(
        this.searchName,
        this.searchEmail,
        this.dataModal['stage_id']
      )
      .subscribe((data) => {
        if (data) {
          this.noData = false;

          if (data.status === 401) {
            // go to login
          } else {
            this.emailsCount = data;

            this.pageService
              .getSearchEmailsCountByStageId(
                this.searchName,
                this.searchEmail,
                this.dataModal['stage_id']
              )
              .subscribe((data) => {
                if (data) {
                  this.noData = false;

                  if (data.status === 401) {
                    // go to login
                  } else {
                    this.searchEmailsCount = data;

                    // reset [items] to empty for deleting items
                    this.commService.items.length = 0;

                    if (
                      this.searchName != '' ||
                      this.searchName != null ||
                      this.searchEmail != '' ||
                      this.searchEmail != null
                    ) {
                      this.count = this.searchEmailsCount;
                      this.pagination(
                        this.emailsCount,
                        this.pageParam
                      );
                    } else {
                      this.count = this.emailsCount;
                      this.pagination(
                        this.searchEmailsCount,
                        this.pageParam
                      );
                    }
                  }
                } else {
                  this.all_emails = null;
                  this.isDataAvailable = true;
                  this.noData = true;
                }
              });
          }
        } else {
          this.all_emails = null;
          this.isDataAvailable = true;
          this.noData = true;
        }
      });
  }

  pagination(aTotal, page, aItemsPerPage = 100, aLinksPerPage = 6) {
    let num_pages,
      first,
      last,
      left_offset,
      from,
      to,
      start = 0;

    if (aTotal && aTotal > aItemsPerPage) {
      num_pages = Math.ceil(aTotal / aItemsPerPage);
      this.current_page = parseInt(page);
      this.current_page =
        this.current_page < 1
          ? 1
          : this.current_page > num_pages
          ? num_pages
          : this.current_page;

      left_offset = Math.ceil(aLinksPerPage / 2) - 1;
      first = this.current_page - left_offset;
      first = first < 1 ? 1 : first;

      last = first + aLinksPerPage - 1;
      last = last > num_pages ? num_pages : last;

      first = last - aLinksPerPage + 1;
      first = first < 1 ? 1 : first;

      this.pagination_pages = this.commService.range(first, last, 1);

      // Previous, First links
      if (this.current_page > 1) {
        this.previous = this.current_page - 1;
      } else {
        this.previous = null;
      }

      // Next, Last links
      if (this.current_page < num_pages) {
        this.next = this.current_page + 1;
      } else {
        this.next = null;
      }

      page = page < 1 ? 1 : page;
      start = (page - 1) * aItemsPerPage;

      from = aTotal > 0 ? start + 1 : start;
      to = aTotal > start + aItemsPerPage ? start + aItemsPerPage : aTotal;
    }

    this.getSomeEmailsByStageId(
      start,
      aItemsPerPage,
      this.searchName,
      this.searchEmail,
      this.dataModal['stage_id']
    );

    this.resultObj = {
      pages: this.pagination_pages,
      current_page: this.current_page,
      total: aTotal,
      previous: this.previous,
      next: this.next,
      from: from,
      to: to,
      aItemsPerPage: aItemsPerPage,
      searchName: this.searchName,
      searchEmail: this.searchEmail
    };
  }

  getSomeEmailsByStageId(
    start,
    aItemsPerPage,
    searchName,
    searchEmail,
    stage_id
  ) {
    this.isDataAvailable = true;
    this.all_emails = [];
    this.noData = false;
    this.pageService
      .getSomeEmailsByStageId(
        start,
        aItemsPerPage,
        searchName,
        searchEmail,
        stage_id
      )
      .subscribe((data) => {
        // console.log(data);
        if (data) {
          this.all_emails = data;
        } else {
          this.noData = true;
        }
      });
  }

  goToSearch(
    searchName,
    searchEmail
  ) {
    if (
      searchName != '' ||
      searchEmail != ''
    ) {
      /*   this.router.navigate(["/courses/all-courses"], {
          queryParams: {
            page: 1,
            sort: this.coursesStatus,
            type: this.sortDirection,
            searchName: searchName,
            searchCountry: searchCountry,
          },
        }); */
      this.searchName = searchName;
      this.searchEmail = searchEmail;
      this.pageParam = 1;
      this.all_emails = [];
      this.getAllData();
    } else {
      this.pageParam = 1;
      this.all_emails = [];
      this.getAllData();
    }
  }

  getAllDataEmailsByStageId() {
    this.pageParam = 1;
    this.searchName = '';
    this.searchEmail = '';
    this.isDataAvailable = false;
    this.getAllData();
  }

  loadingPage(
    page: any,
    searchName: any,
    searchEmail: any
  ) {
    this.pageParam = page;
    this.searchName = searchName;
    this.searchEmail = searchEmail;
    this.all_emails = [];
    this.getAllData();
  }

  deleteEmailConfirm(value) {
    // console.log(value.deleteMultiple);

    if (value.deleteMultiple === 'delete') {
      if (this.commService.items.length !== 0) {
        this.alertService
          .warning({
            title: 'هل انت متأكد ؟',
            text: 'ستكون غير قادر على إستعادة البيانات المفقودة',
            showCancelButton: true,
            confirmButtonText: 'نعم, احذف الآن',
            cancelButtonText: 'لا, إلغـــاء',
            reverseButtons: true,
          })
          .then((result) => {
            if (result.value) {
              if (this.commService.items.length !== 0) {
                this.pageService
                  .deleteEmailsById(this.commService.items)
                  .subscribe((data) => {
                    if (data) {
                      this.pageService
                        .getEmailsCountByStageId(
                          this.searchName,
                          this.searchEmail,
                          this.dataModal['stage_id']
                        )
                        .subscribe((data) => {
                          if (data) {
                            this.all_emails = [];
                            this.pageParam = 1;
                            this.getAllData();
                            this.alertService.success({
                              title: 'حذف الايميلات',
                              text: 'تم حذف الايميلات بنجاح',
                            });

                            $(
                              'input[class="check_all"]:checkbox:checked'
                            ).prop('checked', false);

                            this.deleteForm.reset();
                            this.selectedOption = false;
                            this.deleteMultiple = '';
                          } else {
                            this.all_emails = [];
                            this.pageParam = 1;
                            this.alertService.success({
                              title: 'حذف الايميلات',
                              text: 'تم حذف الايميلات بنجاح',
                            });
                            /* this.coursesCount = 0;
                            this.all_courses = null;
                            this.noData = true;
                            this.count = 0; */
                            $(
                              'input[class="check_all"]:checkbox:checked'
                            ).prop('checked', false);

                            this.deleteForm.reset();
                            this.selectedOption = false;
                            this.deleteMultiple = '';
                          }
                        });
                    } else {
                      console.log('Oops !! ....');
                    }
                  });
              } else {
                console.log('array empty');
              }
            } else if (result.dismiss === 'cancel') {
              this.alertService.error({
                title: 'تم الإلغـــاء',
                text: 'بياناتك ما زالت فى أمان',
              });
            }
          })
          .catch(() => console.log('canceled'));
      }
    }
  }

  goToEditEmail() {
    this.passBack();
      this.router.navigate(['/emails/edit-email/'+this.dataModal['stage_id']]);
    }


  passBack() {
    let reloadData= this.all_emails.length > 0 ? false: true;
    this.activeModal.close(reloadData);
  }
}
