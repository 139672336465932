import {Injectable} from '@angular/core';
// import { Http } from '@angular/http';
import {HttpClient} from '@angular/common/http';

import {CommonService} from '../../../../_services/common.service';

@Injectable()
export class UnitsService {
  private _url: string = this.commonService._hostName + 'admin-api-v1/units.php?action=';
  private user_id;

  constructor(public http: HttpClient, public commonService: CommonService) {
    var currentUser = localStorage.getItem('currentUser');
    var retrievedObject = JSON.parse(currentUser);
    this.user_id = retrievedObject.id;
  }

  getSomeUnits(
    start,
    aItemsPerPage,
    sort,
    type,
    searchName,
    searchMode,
    searchCountry,
    searchStage,
    searchGrade,
    searchSemester,
    searchTestSemester,
    searchSubject
  ) {
    return this.http.get<any>(
      this._url +
      'getSomeUnits&start=' +
      start +
      '&aItemsPerPage=' +
      aItemsPerPage +
      '&sort=' +
      sort +
      '&type=' +
      type +
      '&searchName=' +
      searchName +
      '&searchMode=' +
      searchMode +
      '&searchCountry=' +
      searchCountry +
      '&searchStage=' +
      searchStage +
      '&searchGrade=' +
      searchGrade +
      '&searchSemester=' +
      searchSemester +
      '&searchTestSemester=' +
      searchTestSemester +
      '&searchSubject=' +
      searchSubject
    );
  }

  getSearchUnitsCount(
    sort,
    type,
    searchName,
    searchMode,
    searchCountry,
    searchStage,
    searchGrade,
    searchSemester,
    searchTestSemester,
    searchSubject
  ) {
    return this.http.get<any>(
      this._url +
      'getSearchUnitsCount&sort=' +
      sort +
      '&type=' +
      type +
      '&searchName=' +
      searchName +
      '&searchMode=' +
      searchMode +
      '&searchCountry=' +
      searchCountry +
      '&searchStage=' +
      searchStage +
      '&searchGrade=' +
      searchGrade +
      '&searchSemester=' +
      searchSemester +
      '&searchTestSemester=' +
      searchTestSemester +
      '&searchSubject=' +
      searchSubject
    );
  }

  getUnitsCount(searchMode) {
    return this.http.get<any>(this._url + 'getUnitsCount&searchMode=' + searchMode);
  }

  addEditUnit(formData) {
    return this.http.post<any>(this._url + 'addEditUnit', formData);
  }

  getAllCountries() {
      return this.http.get<any>(this._url + 'getAllCountries&user_id=' + this.user_id);
  }

  getStagesWithCountryId(country_id) {
    return this.http.get<any>(
      this._url + 'getStagesWithCountryId&country_id=' + country_id + '&user_id=' + this.user_id
    );
  }

  getGradesWithStageID(stage_id) {
    return this.http.get<any>(this._url + 'getGradesWithStageID&stage_id=' + stage_id + '&user_id=' + this.user_id);
  }

  getBanksWithSubjectID(subject_id) {
    return this.http.get<any>(
      this._url + 'getBanksWithSubjectID&subject_id=' + subject_id + '&user_id=' + this.user_id
    );
  }

  getUnitsWithSubjectID(subject_id) {
    return this.http.get<any>(
      this._url + 'getUnitsWithSubjectID&subject_id=' + subject_id + '&user_id=' + this.user_id
    );
  }

  // getUnitsWithBranchID(subject_id, branch_id) {
  //   return this.http.get<any>(
  //     this._url + 'getUnitsWithSubjectID&subject_id=' + subject_id + '&user_id=' + this.user_id
  //   );
  // }



  getUnitsWithBranchID(branch_id, subject_id) {
    return this.http.get<any>(
      this._url + 'getUnitsWithBranchID&branch_id=' + branch_id + '&subject_id=' + subject_id + '&user_id=' + this.user_id
    );
  }

  getUnitsWithSubjectIDInSemester(subject_id, semester) {
    return this.http.get<any>(
      this._url +
      'getUnitsWithSubjectIDInSemester&subject_id=' +
      subject_id +
      '&user_id=' +
      this.user_id +
      '&semester=' +
      semester
    );
  }

  getUnitsWithSubjectIDWithExplanationsInSemester(subject_id, semester) {
    return this.http.get<any>(
      this._url +
      'getUnitsWithSubjectIDWithExplanationsInSemester&subject_id=' +
      subject_id +
      '&user_id=' +
      this.user_id +
      '&semester=' +
      semester
    );
  }

  getLessonsWithUnitID(unit_id) {
    return this.http.get<any>(this._url + 'getLessonsWithUnitID&unit_id=' + unit_id);
  }

  getSubjectsWithGradeID(grade_id, semester, specialize_id = '') {
    return this.http.get<any>(
      this._url +
      'getSubjectsWithGradeID&grade_id=' +
      grade_id +
      '&semester=' +
      semester +
      '&specialize_id=' +
      specialize_id +
      '&user_id=' +
      this.user_id
    );
  }


  getSubjectsWithGradeIDForCosts(grade_id, semester, specialize_id = '') {
    return this.http.get<any>(
      this._url +
      'getSubjectsWithGradeIDForCosts&grade_id=' +
      grade_id +
      '&semester=' +
      semester +
      '&specialize_id=' +
      specialize_id +
      '&user_id=' +
      this.user_id
    );
  }

  deleteUnit(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteUnit', body);
  }

  deleteOneLesson(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteOneLesson', body);
  }

  getOneUnit(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getOneUnit', body);
  }

  getAllDataTest() {
    return this.http.get<any>(this._url + 'getAllDataTestInMonths');
  }


}
