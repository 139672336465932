import { Injectable } from '@angular/core';
// import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';


import { CommonService } from '../../../../_services/common.service';

@Injectable()
export class SchoolYearsService {

    private _url: string = this.commonService._hostName + 'admin-api-v1/school_years.php?action=';

    constructor(public http: HttpClient, public commonService: CommonService) { }

    getSomeSchoolYears(start, aItemsPerPage, sort, type, searchName,searchCountry) {
        return this.http.get<any>(this._url + 'getSomeSchoolYears&start=' + start + '&aItemsPerPage=' + aItemsPerPage + '&sort=' + sort + '&type=' + type + '&searchName=' + searchName+ '&searchCountry=' + searchCountry);
    }

    getSearchSchoolYearsCount(sort, type, searchName,searchCountry) {
        return this.http.get<any>(this._url + 'getSearchSchoolYearsCount&sort=' + sort + '&type=' + type + '&searchName=' + searchName+ '&searchCountry=' + searchCountry);
    }

    getSchoolYearsCount() {
        return this.http.get<any>(this._url + 'getSchoolYearsCount');
    }

    addEditSchoolYear(formData) {
        return this.http.post<any>(this._url + 'addEditSchoolYear', formData);
    }

    deleteConfirm(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'deleteConfirm', body);
    }

    getOneSchoolYear(data) {
        let body = JSON.stringify(data);
        return this.http.post<any>(this._url + 'getOneSchoolYear', body);
    }

}
