import { CoursesSubscriptionsService } from './theme/pages/sections/courses_subscriptions/courses-subscriptions.service';
import { SubscriptionCoursesService } from './theme/pages/sections/subscription_courses/subscription-courses.service';
import { DiscountCodesService } from './theme/pages/sections/discount-codes/discount-codes.service';
import { RequestsService } from './theme/pages/sections/balance_withdrawal_requests/requests.service';
import { TeacherCoursesVideosService } from './theme/pages/sections/teacher_courses_videos/teacher-courses-videos.service';
import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ThemeComponent} from './theme/theme.component';
import {LayoutModule} from './theme/layouts/layout.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ScriptLoaderService} from './_services/script-loader.service';
import {ThemeRoutingModule} from './theme/theme-routing.module';

import {AuthGuard} from './auth.guard';

import {FormsModule, ReactiveFormsModule, NgForm} from '@angular/forms';

// Start Main Components
import {UserService} from './_services/user.service';
import {CommonService} from './_services/common.service';


import {SettingsService} from './theme/pages/main-components/settings/settings.service';
import {PagesService} from './theme/pages/main-components/pages/pages.service';
import {ArticlesService} from './theme/pages/sections/articles/articles.service';
import {CategoriesService} from './theme/pages/sections/categories/categories.service';
import {PagesStaticService} from './theme/pages/sections/pages_static/pages_static.service';
import {ContactInformationService} from './theme/pages/sections/contact_information/contact_information.service';

import {LevelsService} from './theme/pages/main-components/levels/levels.service';

import {TokenInterceptorService} from './token-interceptor.service';
// End Main Components

// Start Main Sections

import {LanguagesService} from './theme/pages/sections/languages/languages.service';
import {SchoolYearsService} from './theme/pages/sections/school_years/school_years.service';
import {FrequentlyQuestionsService} from './theme/pages/sections/frequently_questions/frequently_questions.service';
import {SubjectsService} from './theme/pages/sections/subjects/subjects.service';
import {UnitsService} from './theme/pages/sections/units/units.service';
import {AdvantagesService} from './theme/pages/sections/advantages/advantages.service';
import {ExplanationsService} from './theme/pages/sections/explanations/explanations.service';


import {CountriesService} from './theme/pages/sections/countries/countries.service';
import {GradesService} from './theme/pages/sections/grades/grades.service';
import {PrintableDataService} from './theme/pages/sections/printable_data/PrintableDataService.service';

import {TestsService} from './theme/pages/sections/tests/tests.service';
import {ExamineQuestions} from './theme/pages/sections/examine_questions/examine_questions.service';
import {ImportantReviews} from './theme/pages/sections/important_reviews/important_reviews.service';
import {TestsPrintableService} from './theme/pages/sections/tests_printable/tests_printable.service';
import {StudentsAnswersService} from './theme/pages/sections/students_answers/students_answers.service';
import {QuestionsBankService} from './theme/pages/sections/questions_bank/questions_bank.service';
import {StagesService} from './theme/pages/sections/stages/stages.service';
import {CoursesService} from './theme/pages/sections/courses/courses.service';
import {GroupsService} from './theme/pages/sections/groups/groups.service';
import {SubscriptionCostsService} from './theme/pages/sections/subscription_costs/subscription_costs.service';
import {SlidersService} from './theme/pages/sections/sliders/sliders.service';
import {BannersService} from './theme/pages/sections/banners/banners.service';
import {NotificationsService} from './theme/pages/main-components/notifications/notifications.service';


// End Main Sections
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';


import {RouterModule} from '@angular/router';

import {SweetAlertService} from 'angular-sweetalert-service';

import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {MathModule} from './../math/math.module';
import {saveAs} from 'file-saver';
import { EmailsService } from './theme/pages/sections/emails/emails.service';
import {ShowEmailModalComponent} from './theme/pages/sections/show-email-modal/show-email-modal.component';
import { ShowEmailModalService } from './theme/pages/sections/show-email-modal/show-email-modal.service';
import { OrderByPipe } from "./_pipes/order-by.pipe";
import { ReplaceSubstring } from "./_pipes/replace-substring.pipe";
import {VisitorBannersService} from './theme/pages/sections/visitor_banners/visitor-banners.service';
import { ReviewNotesService } from './theme/pages/sections/review_notes/review-notes.service';
import { ReviewVideosService } from './theme/pages/sections/review_videos/review-videos.service';
// import { QuestionsReportsComponent } from './theme/pages/sections/questions-reports/questions-reports.component';

@NgModule({
  declarations: [
    ThemeComponent,
    AppComponent,
    ShowEmailModalComponent,
    OrderByPipe,
    ReplaceSubstring,
  ],
  imports: [
    RouterModule,
    LayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ThemeRoutingModule,

    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    FormsModule, MathModule.forRoot(),
    NgbModule,


    // NgbModule.forRoot(),
  ],
  providers: [
    ScriptLoaderService,
    CommonService,
    UserService,
    SettingsService,
    NotificationsService,
    PagesService,
    ArticlesService,
    CategoriesService,
    PagesStaticService,
    ContactInformationService,
    AdvantagesService,
    ExplanationsService,
    TestsService,
    ExamineQuestions,
    ImportantReviews,
    TestsPrintableService,
    StudentsAnswersService,
    QuestionsBankService,
    LevelsService,
    LanguagesService,
    SchoolYearsService,
    FrequentlyQuestionsService,
    SubjectsService,
    UnitsService,
    PrintableDataService,
    CountriesService,
    GradesService,
    StagesService,
    CoursesService,
    GroupsService,
    SubscriptionCostsService,
    SlidersService,
    BannersService,
    VisitorBannersService,
    EmailsService,
    ShowEmailModalService,
    ReviewNotesService,
    ReviewVideosService,
    TeacherCoursesVideosService,
    RequestsService,
    SweetAlertService,
    DiscountCodesService,
    CoursesSubscriptionsService,
    Title,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],


  bootstrap: [AppComponent],
  entryComponents:[ShowEmailModalComponent]
})
export class AppModule {
}
